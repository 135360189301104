export const homeReinsurance = [
  {
    slug: 'doctor',
    text: 'Médecins spécialistes français ',
    icon: 'doctor-talking.svg',
    alt: "docteur-qui-parle",
    title: "docteur qui parle"
  },
  {
    slug: 'accompaniment',
    text: 'Diagnostics complets',
    icon: 'tick-list.svg',
    alt: "diagnostics-complets",
    title: "check list"
  },
  {
    slug: 'treatment',
    text: 'Accompagnement de bout en bout',
    icon: 'phone-sparkling.svg',
    alt: "accompagnement-medical",
    title: "boite mail"
  },
];
