import { useStaticQuery, graphql } from "gatsby";

export default (): any[] => {
  const {
    allWpPost: { nodes },
  } = useStaticQuery(graphql`
    query {
      allWpPost(
        filter: { tags: { nodes: { elemMatch: { name: { eq: "home" } } } } }
        sort: { fields: date, order: DESC }
        limit: 12
      ) {
        nodes {
          ...BlogPostThumbnail
        }
      }
    }
  `);
  return nodes;
};
