import React from "react";
import { Link } from "gatsby";
import cx from "classnames";
import Container from "react-bootstrap/Container";
import "./styles.sass";
import Image from "../utils/Image";

export default (props) => {
  const { reinsuranceList, className, onlyMobile = false } = props;
  return (
    <div
      className={cx("reinsurance-wrapper", className, {
        "d-xl-none": onlyMobile,
      })}
    >
      {reinsuranceList.map((el, i) => (
        <div className="reinsurance-div" key={`reinsuranceList-${i}`}>
          <Image
            filename={`new-images/${el.icon}`}
            title={el.title}
            alt={el.alt}
          />
          <span>{el.text}</span>
        </div>
      ))}
    </div>
  );
};

export const NewReinsurance = ({ reinsuranceList, onlyDesktop = true }) => {
  return (
    <div
      className={cx("reinsurance-section", { "d-none d-xl-flex": onlyDesktop })}
    >
      <Container>
        <div className="reinsurance-section-container">
          {reinsuranceList.map((el, i) => (
            <div className="reinsurance-item" key={`reinsuranceList-${i}`}>
              <Image
                className="reinsurance-item-image"
                alt={el.alt}
                title={el.title}
                filename={`new-images/${el.icon}`}
              />
              <div className="reinsurance-item-text">{el.text}</div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};
