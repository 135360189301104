import CharlesMeta from "components/common/meta/CharlesMeta";
import Segment from "utils/segment";
import Image from "components/common/utils/Image";
import { Layout } from "components/common";
import Cta from "components/common/Cta";
import React from "react";
import StructuredOrganization from "components/common/meta/StructuredOrganization";
import YellowUnderline from "images/new-images/yellow-pen-underline.svg";
import { homeReinsurance } from "components/common/data/reinsurance";
import Reinsurance from "components/common/Reinsurance";
import { scientists } from "components/Landing/data";
import Section from "components/common/Section";
import { Container } from "react-bootstrap";
import FooterCities from "components/common/FooterCities";

import "./styles.sass";
import PressBanner from "components/Press/PressBanner";
import HowItWorksSection from "components/HowItWorksSection";
import MedicalSpecialties from "components/MedicalSpecialties";
import ScientistSection from "components/ScientistSection";
import { useAirtableCategories } from 'hooks/useAirtableCategories';
import usePostsTaggedHome from "hooks/usePostsTaggedHome";
import { AirtableImages } from "components/common/utils/AirtableImage";
import { Link } from "gatsby";
import { annuarySlug } from "templates/Annuary/utils/slugs";
import Carousel from "templates/Blog/common/PostList/Carousel";
import Breadcrumb, { pageBreadcrumbs } from "templates/Blog/common/Breadcrumb";
import ReviewSection from "components/ReviewSection";

const CategoriesSection = () => {
  return (
    <div className="landing-specialties-card-wrapper">
      {useAirtableCategories(true).map((categoryRecord) =>
        categoryRecord.data.home_display_on_top ? (
          <a
            key={`#${categoryRecord.data.slug}-card`}
            href={categoryRecord.data.consultation_direct_link}
            className="landing-specialty-card"
            style={{ border: `3px solid ${categoryRecord.data.color}` }}
          >
            <div className="landing-specialty-card-image">
              <AirtableImages images={categoryRecord.data.color_icon} />
            </div>
            <div className="landing-specialty-card-name">
              <span>{categoryRecord.data.name}</span>
              <Image
                filename="new-images/right-tag.svg"
                alt="fleche-droite-picto"
                title="fleche droite picto"
              />
            </div>
          </a>
        ): null)}
    </div>
  )
}

const IndexPage = () => (
  <Layout showLinksInFooter={false}>
    <CharlesMeta
      data={{
        title: "Consultez en ligne un médecin et recevez un diagnostic personnalisé | Charles",
        description:
          "Consultation en ligne en moins de 24h et 7j/7 avec des médecins spécialistes en sexologie, fertilité, troubles du sommeil et chute de cheveux.",
      }}
      segmentCategory={Segment.CATEGORIES.HOME}
    />
    <StructuredOrganization />
    <div className="landing-home-main-div">
      <div className="landing-home-top-div">
        <div className="landing-home-top-text">
          <h1 className="d-inline h1 landing-home-h1">
            Charles prend soin de votre <span className="yellow-highlight">santé<YellowUnderline className="yellow-underline" /></span>
          </h1>
          <span className="landing-home-subtitle">
            Solutions complètes, efficaces et personnalisées pour votre santé au
            quotidien.
          </span>
        </div>
        <div className="landing-specialties-and-image-wrapper">
          <div className="landing-specialties">
            <CategoriesSection />
            <Cta
              path="/consulter/"
              text="Consultez un médecin"
              className="new-primary-cta landing-home-cta"
            />
          </div>
          <div className="landing-home-main-image">
            <Image
              filename="new-images/landing-home-top-image.png"
              title="landing home top image"
              alt="landing-home-top-image"
            />
          </div>

        </div>
        <Reinsurance
          className="reinsurance-wrapper-home"
          reinsuranceList={homeReinsurance}
        />
      </div>
      <div className="landing-home-main-hr" />
      <PressBanner />
      <HowItWorksSection hideNavbarOverSection />
      <MedicalSpecialties />
      <ScientistSection
        version=""
        scientists={scientists}
        startSlug={"/consulter/"}
      />
      <ReviewSection />
      <Section className="landing-section-white">
        <Container className="px-0">
          <Link to={annuarySlug()}>
            <h2 className="h2 text-left mb-4">Rechercher un sexologue par ville</h2>
          </Link>
          <FooterCities />
        </Container>
      </Section>
      <Section className="landing-section-white">
        <Container className="my-5">
          <Carousel
            title="Nos derniers articles médicaux"
            className="mb-4"
            posts={usePostsTaggedHome()}
          />
          <Breadcrumb links={pageBreadcrumbs()} />
        </Container>
      </Section>
    </div>
  </Layout>
);

export default IndexPage;
