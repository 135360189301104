import React from 'react';
import LandingPage from 'components/Landing';

// import Image from 'components/image'@
// import SEO from 'components/seo'
/* {
  <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
} */

const IndexPage = () => (
  <>
    <LandingPage />

  </>
);

export default IndexPage;
